var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "g-timeaxis",
      staticClass: "g-timeaxis",
      style: { width: `${_vm.timeCount * _vm.gridSize + _vm.rowLabelWidth}px` },
    },
    [
      _c("div", {
        staticClass: "g-timeaxis-empty-space",
        style: {
          minWidth: `${_vm.rowLabelWidth}px`,
          background: _vm.themeColors.primary,
        },
      }),
      _c(
        "div",
        { staticClass: "g-timeaxis-days" },
        _vm._l(_vm.axisPoints, function (point, index) {
          return _c(
            "div",
            {
              key: point.text,
              staticClass: "g-timeaxis-day",
              style: {
                background:
                  index % 2 === 0
                    ? _vm.themeColors.primary
                    : _vm.themeColors.secondary,
                color: _vm.themeColors.text,
              },
            },
            [
              _c("div", [
                _c("div", {
                  staticClass: "g-timeaxis-day__label",
                  domProps: {
                    innerHTML: _vm._s(_vm.pointFormatted(point) || "&nbsp;"),
                  },
                }),
              ]),
              _c(
                "div",
                {
                  style: {
                    background: _vm.themeColors.ternary,
                    color: _vm.themeColors.text,
                  },
                },
                _vm._l(point.childPoints, function (childPoint, index) {
                  return _c(
                    "div",
                    {
                      key: childPoint.fullDatetime,
                      staticClass: "g-timeaxis-hour",
                      style: {
                        width: `${_vm.gridSize}px`,
                        background:
                          index % 2 === 0
                            ? _vm.themeColors.primary
                            : _vm.themeColors.secondary,
                        color: _vm.themeColors.text,
                      },
                    },
                    [
                      _c("span", { style: { fontSize: _vm.hourFontSize } }, [
                        _vm._v(" " + _vm._s(childPoint.text) + " "),
                      ]),
                      _c("div", {
                        staticClass: "g-timeaxis-hour-pin",
                        style: { background: _vm.themeColors.text },
                      }),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        }),
        0
      ),
      _c("div", { ref: "g-timeaxis-marker", staticClass: "g-timeaxis-marker" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }